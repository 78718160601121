<template>
    <tbody>

        <tr class="result-item" :class="{'has-child': !isUndefined(result.children) && result.children.length}">
            <td class="result-item-photo padding" @click="openResult(result)">
                
                <div style="display: flex; align-items: center">
                    
                    <div class="d-inline-block result-item-photo-container m-r-10"
                        v-if="(typeof result.image === 'undefined'
                          && typeof result.image_url === 'undefined'
                          && typeof result.imageFromResults === 'undefined') || result.dontNeedLoad ">
                        <image-profile
                            v-if="typeof result.value !== 'undefined' && result.dontNeedLoad"
                            @click="openModal"
                            @imgSource="onLoadImg"
                            :from-search-list-child="true"
                            :size="'64px'"
                            :payload="result.value"
                        />
                        <img :src="`${prefix}images/no_photo_`+`${$root.$i18n.locale}`+`.png`" v-else-if="result.loaded">
                        <img :src="`${prefix}images/user-found.png`" v-else>
                    </div>
                    <div class="d-inline-block result-item-photo-container m-r-10" v-else>
                        <image-profile
                            v-if="typeof result.image_url !=='undefined'"
                            :payload="result.image_url"
                            @imgSource="onLoadImg"
                            @click="openModal"
                            :from-search-list-child="true"
                            :size="'64px'"
                        />
                        <image-profile :size="'64px'" v-else-if="result.photosCount === 1 && result.imageFromResults"
                         :payload="result.imageFromResults" @click.self="openModal" />
                        <img :src="`${prefix}images/user-found.png`"  v-else alt="">
                        <span class="badge badge-primary" :content="$t('settings.photos_includes', {count: result.photosCount })" v-tippy="{ placement : 'top',  arrow: true,  }" v-if="result.photosCount">{{ result.photosCount }}</span>
                    </div>
                    <div>
                      <div style="display: flex;flex-direction: column; justify-content: center">
                        <h6 style="margin-bottom: 0; word-break: break-all; white-space: break-spaces;" v-if="result.param !== 'full_search' && result.param !== 'geo_search_telegram'" >{{ result.param !== 'image'  ?$t('side_menu.'+result.param) +': '+ result.value :  $t('search_list_item.file') }}</h6>
                        <h6 style="margin-bottom: 0" v-else-if="result.param === 'geo_search_telegram'">{{$t('side_menu.geo_search_telegram')}}:</h6>
                        <h6 style="margin-bottom: 0" v-else>{{$t('side_menu.all_data')}}:</h6>
                        <p v-if="result.param !== 'full_search' && result.param !== 'geo_search_telegram'" class="text-muted m-b-0" style="white-space: break-spaces">{{ $t("search_list_item.options") }}: <span
                            v-for="(item, k) in visualOptions" :key="k">{{
                            $t(`visualOptions.${item}`) }}{{ k ===
                          visualOptions.length - 1 ? '' : ', '
                          }}</span>
                        </p>
                        <div v-else-if="result.typeSearch === 'simple_search'" class=" m-b-0">
                          <span style="display:flex;">
                            <span class="text-muted">
                                <div class="optionsWidth" ref="optionsFilters">
                                  <div>
<!--                                     <span v-if="simpleSearchOptionsFilter(result.filters).match.length">{{ucFirst($t('simpleSearchListItem.match_query'))}} </span>-->
                                     <span style="white-space: break-spaces">{{simpleSearchOptionsFilter.match.map(item=> item.replace(/,*$/, '')).join(', ')}}</span>
                                  </div>
                                  <div>
                                    <span v-if="simpleSearchOptionsFilter.notMatch.length">{{ucFirst($t('simpleSearchListItem.not_match_query'))}} </span>
                                    <span style="white-space: break-spaces">{{simpleSearchOptionsFilter.notMatch.map(item=> item.replace(/,*$/, '')).join(', ')}}</span>
                                  </div>
                                </div>
                            </span>
                            <span v-if="checkLengthFilter() || typeof result.filters !== 'undefined' && result.filters.length > 3" style="display:flex; justify-content: center; align-items: center; margin-left: 1rem;">
                               <tippy
                                   interactive
                                   :animate-fill="false"
                                   placement="bottom"
                                   distant="7"
                                   theme="light"
                                   animation="shift-toward"
                                   trigger="mouseenter"
                                   offset="interactive:true"
                                   arrow>
                                <template v-slot:trigger>
                                  <i class="feather icon-info primaryIcon" style="width: auto; height: auto; box-shadow: none; margin-right: 0 "  data-bs-toggle="dropdown" aria-expanded="false"  @click.stop ></i>
                                </template>

                                <span>
                                  <span style="display:flex;">
                                      <span>
                                        <div>
<!--                                           <span v-if="simpleSearchOptionsFilter.match.length">{{ucFirst($t('simpleSearchListItem.match_query'))}} </span>-->
                                           <span>{{simpleSearchOptionsFilter.match.map(item=> item.replace(/,*$/, '')).join(', ')}}</span>
                                        </div>

                                        <div>
                                          <span v-if="simpleSearchOptionsFilter.notMatch.length">{{ucFirst($t('simpleSearchListItem.not_match_query'))}} </span>
                                          <span >{{simpleSearchOptionsFilter.notMatch.map(item=> item.replace(/,*$/, '')).join(', ')}}</span>
                                        </div>
                                      </span>
                                   </span>
                                </span>
                              </tippy>
                            </span>
                          </span>

                        </div>
                        <div v-else-if="result.typeSearch === 'full_search'" class=" m-b-0">
                          <div style="display:flex;">
                            <span class="text-muted">
                              <div class="optionsWidth" ref="optionsFilters">
                                <span v-if="typeof result.location !== 'undefined' && result.location.length && typeof result.address === 'undefined'">{{$t('params.geo_search')}}</span>
                                <span v-if="typeof result.location !== 'undefined' &&result.location.length && typeof result.address !== 'undefined'">{{$t('params.geo_search')}}: {{result.address}}</span>
                                <div v-for="obk of Object.keys(fullSearchOptionsFilter)">
                                   <span v-if="typeof fullSearchOptionsFilter[obk][0].fields !=='undefined'">
                                     {{fullSearchOptionsFilter[obk][0].fields.map((it) => $t('params.' + it)).join(", ") | capitalize
                                     }}</span><span v-else>{{$t('fullText.cats.allData')}}</span>{{ 
                                      $t(`fullTextSearchListItem.${fullSearchOptionsFilter[obk][0].optional}`)
                                      }}{{$t(`fullTextSearchListItem.${fullSearchOptionsFilter[obk][0].operation}`)}}
                                   {{fullSearchOptionsFilter[obk].map(it => makeDateFormat(it.value)).join(', ')}}
                                </div>
                              </div>
                            </span>
                            <div style="display:flex; justify-content: center; align-items: center; margin-left: 1rem;" v-if="checkLengthFilter() || typeof result.filters !== 'undefined' && result.filters.length > 3 || (typeof result.location !== 'undefined' && result.location.length && typeof result.address !== 'undefined')">
                               <tippy
                                   interactive
                                   :animate-fill="false"
                                   placement="bottom"
                                   distant="7"
                                   theme="light"
                                   animation="shift-toward"
                                   trigger="mouseenter"
                                   offset="interactive:true"
                                   :maxWidth="screenshot && !isMobile ? 'none': 350"
                                   arrow>
                                <template v-slot:trigger>
                                  <i class="feather icon-info primaryIcon" style="width: auto; height: auto; box-shadow: none; margin-right: 0 "  data-bs-toggle="dropdown" aria-expanded="false"  @click.stop ></i>
                                </template>

                                <div :class="{'tooltip-with-map': !!screenshot}" style="margin-bottom: 4px">
                                  <div>
                                     <span v-if="typeof result.location !== 'undefined' && result.location.length && typeof result.address === 'undefined'">{{$t('params.geo_search')}}</span>
                                     <span v-if="typeof result.location !== 'undefined' && result.location.length && typeof result.address !== 'undefined'">{{$t('params.geo_search')}}: {{result.address}}</span>
                                    <div v-for="obk of Object.keys(fullSearchOptionsFilter)">
                                      <span v-if="typeof fullSearchOptionsFilter[obk][0].fields !=='undefined'">
                                     {{fullSearchOptionsFilter[obk][0].fields.map((it) => $t('params.' + it)).join(", ") | capitalize
                                        }}</span><span v-else>{{$t('fullText.cats.allData')}}</span>{{
                                      $t(`fullTextSearchListItem.${fullSearchOptionsFilter[obk][0].optional}`)
                                      }}{{$t(`fullTextSearchListItem.${fullSearchOptionsFilter[obk][0].operation}`)}}
                                      {{fullSearchOptionsFilter[obk].map(it => makeDateFormat(it.value)).join(', ')}}
                                    </div>
                                    <img class="map-preview"  :src="screenshot" v-if="screenshot" alt="">
                                   </div>
                                  </div>
                              </tippy>
                            </div>
                          </div>
                        </div>



                        <div v-else-if="result.typeSearch === 'geo_search_telegram'" class=" m-b-0">
                          <div style="display:flex;">
                            <span class="text-muted">
                              <div class="optionsWidth" ref="optionsFilters">
                                <span v-if="typeof result.location !== 'undefined'  && typeof result.address !== 'undefined'">{{$t('side_menu.geo_search_telegram')}}: {{result.address}}</span>
                                <div v-for="obk of Object.keys(fullSearchOptionsFilter)">
                                   <span v-if="typeof fullSearchOptionsFilter[obk][0].fields !=='undefined'">
                                     {{fullSearchOptionsFilter[obk][0].fields.map((it) => $t('params.' + it)).join(", ") | capitalize
                                     }}</span><span v-else>{{$t('fullText.cats.allData')}}</span>{{
                                    $t(`fullTextSearchListItem.${fullSearchOptionsFilter[obk][0].optional}`)
                                  }}{{$t(`fullTextSearchListItem.${fullSearchOptionsFilter[obk][0].operation}`)}}
                                   {{fullSearchOptionsFilter[obk].map(it => makeDateFormat(it.value)).join(', ')}}
                                </div>
                              </div>
                            </span>
                            <div style="display:flex; justify-content: center; align-items: center; margin-left: 1rem;" v-if="checkLengthFilter() || typeof result.filters !== 'undefined' && result.filters.length > 3 || (typeof result.location !== 'undefined' && typeof result.address !== 'undefined')">
                              <tippy
                                  interactive
                                  :animate-fill="false"
                                  placement="bottom"
                                  distant="7"
                                  theme="light"
                                  animation="shift-toward"
                                  trigger="mouseenter"
                                  offset="interactive:true"
                                  :maxWidth="screenshot && !isMobile ? 'none': 350"
                                  arrow>
                                <template v-slot:trigger>
                                  <i class="feather icon-info primaryIcon" style="width: auto; height: auto; box-shadow: none; margin-right: 0 "  data-bs-toggle="dropdown" aria-expanded="false"  @click.stop ></i>
                                </template>

                                <div :class="{'tooltip-with-map': !!screenshot}" style="margin-bottom: 4px">
                                  <div>
                                    <span v-if="typeof result.location !== 'undefined' && typeof result.address !== 'undefined'">{{result.address}}</span>
                                    <div v-for="obk of Object.keys(fullSearchOptionsFilter)">
                                      <span v-if="typeof fullSearchOptionsFilter[obk][0].fields !=='undefined'">
                                     {{fullSearchOptionsFilter[obk][0].fields.map((it) => $t('params.' + it)).join(", ") | capitalize
                                        }}</span><span v-else>{{$t('fullText.cats.allData')}}</span>{{
                                        $t(`fullTextSearchListItem.${fullSearchOptionsFilter[obk][0].optional}`)
                                      }}{{$t(`fullTextSearchListItem.${fullSearchOptionsFilter[obk][0].operation}`)}}
                                      {{fullSearchOptionsFilter[obk].map(it => makeDateFormat(it.value)).join(', ')}}
                                    </div>
                                    <img class="map-preview"  :src="screenshot" v-if="screenshot" alt="">
                                  </div>
                                </div>
                              </tippy>
                            </div>
                          </div>
                        </div>



                      </div>
                    </div>
                </div>
            </td>
            <td class="padding" @click="openResult(result)">

                <h6 v-if="!result.error && result.countResult > 0" style="white-space: break-spaces">
                  <span v-if="result.name !== 'No name' && typeof result.name !== 'undefined'">{{ result.name !== 'many faces' ? result.name+ ', ...' : $t('search_list_item.many faces') }}</span>
                  <span v-else-if="typeof result.name === 'undefined'">{{ 'No name' }}</span>
                  <span v-else>{{ result.name !== 'many faces' ? result.name : $t('search_list_item.many faces') }}</span>
                  <small class="d-inline d-lg-none" v-if="result.countResult > 0 && result.loaded">({{result.countResult}})</small>
                </h6>
                <h6 v-else-if="!result.error && result.countResult === 0 && result.param !== 'image'">
                  <i class="feather icon-info"></i>
                  {{ $t('errors.not_found') }}
                </h6>
                <h6 v-else-if="result.error" style="white-space: break-spaces">
                  <i class="feather icon-x-circle"></i>
                  {{ $t($options.filters.errorTranslate(result.errorName ? result.errorName : 'Undefined error')) }}
                </h6>
            </td>
            <td class="padding d-lg-table-cell" @click="openResult(result)">
                <div class="d-flex justify-content-center d-lg-block">
                    <span class="d-none d-lg-block" v-if="result.loaded"><span v-if="result.countResult > 0">{{ resultsCount }}</span></span>
                    <span v-else>
                        <b-spinner variant="secondary"></b-spinner>
                    </span>
                </div>
            </td>
            <td class="padding d-none d-lg-table-cell">
              <div style="display: flex; flex-direction: column">
                <div class="d-none d-lg-inline">{{ result.date | moment('DD.MM.YYYY') }}</div>
                <div class="d-none d-lg-inline">{{ result.date | moment('HH:mm') }}</div>
              </div>
            </td>

            <td class="padding d-flex d-lg-table-cell" >
              <div class="d-inline d-lg-none"> {{ result.date | moment('DD.MM.YYYY, HH:mm') }}</div>
              <search-cost
                  v-if="result.totalCost >= 0 && !result.error && !result.is_multiple && result.totalCost !== null"
                  style="margin-top: 2px"
                  :header='header'
                  :cost="result.cost"
                  :total-cost="result.totalCost"
                  :ident="ident"
              />
            </td>

            <td class="text-right padding">
<!--                <div class="d-none d-lg-flex" style="justify-content: flex-end;margin-right: 16px">-->
                <div class="d-none d-lg-block" style="margin-left: 12px">

                  <a href="#" data-bs-toggle="modal" v-b-modal="`edit_${ident}`" v-if="['keep'].includes(result.param)"
                      v-show="result.loaded">
                      <i class="icon feather icon-edit f-w-600 f-16 m-r-15 editIconColor"></i>
                  </a>
                  <a href="#" @click.prevent="$refs['delete-modal'].show()" v-show="result.loaded" >
                    <i class="feather icon-trash-2 f-w-600 f-16  text-c-red" :content="$t('modal.deleteResult')" v-tippy="{ placement : 'top',  arrow: true }"></i>
                  </a>
                  <span v-show="result.loaded && result.typeSearch === 'full_search' && canBeEdit()"
                    @click="editFullTextSearch(result)">
                    <i class="icon feather icon-edit f-w-600 f-16 m-l-15 editIconColor" :content="$t('result.edit_result')" v-tippy="{ placement : 'top',  arrow: true }"></i>
                  </span>

                  <a href="#" @click.prevent="stopSearch" v-if="!result.loaded">
                    <i class="feather icon-x f-w-600 f-w-600 f-16  text-c-red"></i>
                  </a>

                </div>
                <div class="btn-group d-lg-none" style="width: 100%;">

                  <a href="#" class="btn btn-primary"
                  v-show="result.loaded && result.param === 'full_search'  && canBeEdit()
                    && result.typeSearch === 'full_search'"
                    @click.prevent="editFullTextSearch(result)"
                    >
                    <i class="icon feather icon-edit"></i>
                  </a>


                    <a href="#" class="btn btn-danger" @click.prevent="$refs['delete-modal'].show()" v-show="result.loaded"><i
                            class="feather icon-trash-2"></i></a>
                </div>
            </td>
            <b-modal :id="`delete_${ident}`" ref="delete-modal" centered hide-footer :title=" $t('search_list_item.the_result_will_be_deleted_proceed')">
                <div class="d-grid">
                  <b-button class="mt-3" variant="danger" block @click="deleteSearchItem()">{{$t('modal.actionDelete') }}</b-button>
                  <b-button class="mt-2" variant="secondary" block @click="hideModal">{{ $t('modal.cancel') }}</b-button>
                </div>
            </b-modal>

            <b-modal :id="`edit_${ident}`" ref="edit-modal" centered hide-footer :title="$t('result.update_result')">
              <b-input :placeholder="$t('settings.login')" expanded v-model="result.value"></b-input>
              <div class="d-grid">
                <b-button class="mt-3" variant="outline-secondary" block @click="hideModal">{{ $t('modal.cancel') }}</b-button>
                <b-button class="mt-2" variant="outline-danger" block @click="editModalExecute(result.value)">{{
                    $t('result.update_result') }}</b-button>
              </div>
            </b-modal>

            <b-modal :id="`edit_full_${ident}`" ref="edit-full-modal" centered hide-footer  :title="this.$t('fullText.would_use_data')">
              <div class="d-grid">
                <b-button class="mt-3" variant="outline-primary" block @click="editFullTextSearch(result)">{{ $t('modal.yes') }}</b-button>
                <b-button class="mt-2" variant="outline-secondary" block @click="$refs['edit-full-modal'].hide()">{{ $t('modal.no') }}</b-button>
              </div>
            </b-modal>

            <b-modal v-if="typeof photo !== 'undefined' " size="lg" hide-footer
                v-model="isPhotoSegments" :width="640" scroll="keep" centered>
              <face-inner :search-result="result"
                          :photo="photo"
                          :search="search"
                          :meta="meta"
                          :imgBase="imgBase"
                          :from-main-search="true"/>
            </b-modal>
            
        </tr>
        <component v-if="!isUndefined(result.children) && result.children.length"
            v-for="(child, index) in resultChildren" :result="result" :child="child" :key="index" @deleteChild="deleteChild" :is="childrenTypes[child.type]" />
    </tbody>
</template>
<script>
import ImageProfile from '../ImageProfile.vue';
import { mapGetters } from 'vuex';
import openResult from "@/mixins/openResult";
import modalInfo from '../layouts/modalInfo.vue';
import ModalPrompt from '../layouts/modalPrompt.vue';
import filters from "../../mixins/filters";
import imgSearch from '../../mixins/imgSearch';
import FaceInner from '../results/FaceInner.vue';
import SearchListItemChildPhoto from './SearchListItemChildPhoto.vue';
import SearchListItemChildLocation from "@/components/list/SearchListItemChildLocation";
import env from "../../configs/env.json";
import _ from "underscore";
import SearchCost from "@/components/billing/searchCost";
import moment from "moment";
import { ImageStorage } from "@/mixins/imageStorage"
import SearchListItemChildTelegramMonitor from "@/components/list/SearchListItemChildTelegramMonitor.vue";

export default {
    props: ['result', 'ident'],
    mixins: [openResult, filters, imgSearch],
    components: {
        SearchCost,
        ImageProfile,
        modalInfo,
        ModalPrompt,
        FaceInner,
        SearchListItemChildPhoto
    },
    setup() {
      return {
        moment,
        SearchListItemChildPhoto,
        isUndefined: _.isUndefined,
        childrenTypes: {
          'movementHistory': SearchListItemChildLocation,
          detalizeLocation: SearchListItemChildLocation,
          'undefined': SearchListItemChildPhoto,
          telegram_monitor_preview: SearchListItemChildTelegramMonitor
        }
      }
    },
    data() {
        return {
            showLoader: false,
            search: true,
            needShow: false,
            imgBase: {},
            isPhotoSegments: false,
            photo: typeof this.result.photo !== 'undefined' ? this.result.photo : {},
            fullImageLoaded: false,
            prefix: env.prefix,
            resultFromCache: {},
            header : false,
            fullSearchOptions: [],
            screenshot: null,
            meta: {}
        }
    },
    computed: {
        resultsCount(){
          return this.result.countResult
        },

        resultChildren() {
          return this.result.children.map(item => {
                let result = !_.isUndefined(item.faceResults) ? item.faceResults : item.countResult
                let name = '';
                if(!_.isUndefined(item.name)) {
                  if ( item.name === 'No name'){
                    name = item.name
                  } else {
                    name = item.name[0]
                  }
                }
                return {
                    type: item.type,
                    name: name,
                    param: item.param,
                    photosCount: item.photosCount,
                    imageFromResults: item.imageFromResults,
                    value: item.value,
                    childTitle: result ? `${this.$t('search_list_item.found_in')} ${result} ${this.$t('search_list_item.results_for_count')}` : this.$t('search_list_item.search_by_photo'),
                    resultCount: result || 0,
                    date: item.date,
                    keyVal: item.keyVal,
                    face_select: typeof item.face_select !== 'undefined' ? item.face_select : undefined,
                    index: item.index,
                    payload: item.payload,
                    cost: item.cost,
                    totalCost: item.totalCost,
                    countResult: item.countResult,
                    image_url: !_.isUndefined(result) && typeof result.image_url !== 'undefined' ? result.image_url : undefined
                }
            })

        },
        visualOptions: {
            get: function () {
                const keys = Object.keys(this.result);
                const options = [];
                for (const key of keys) {
                  if(typeof this.result[key] !== 'undefined') {
                    if(key !== 'service_options') {
                      options.push(`${key}_${this.result[key]}`);
                    } else {
                      if(!_.isUndefined(this.result[key].GetContact)) {
                          options.push(`get_contact_${this.result[key].GetContact.request_options.tags}`);
                      }
                    }
                  }
                }
                return options.filter(item => [
                    'advanced_false',
                    'offline_true',
                    'offline_false',
                    'online_search_enabled_true',
                    'cache_search_enabled_true',
                    // 'service_types_online',
                    'service_types_hlr',
                    'advanced_true',
                    'iterative_search_true',
                    'iterative_search_false',
                    'service_types_online,hlr',
                    'get_contact_true',
                    'services_Locator',
                    'service_types_hlr,online'].includes(item)).filter(item => {
                      return true;
                    });
            }

        },

        ...mapGetters('contacts', ['payload']),
        ...mapGetters('auth', ['permissions']),
        fullSearchOptionsFilter(){
          const filters = !_.isUndefined(this.result.filters) ? this.result.filters : [];
          const plane = [];
          for (const filter of filters) {
            for (const cond of filter.conditions) {
              plane.push({
                fields: filter.fields,
                optional: filter.optional,
                operation: cond.operation,
                value: cond.value
              });
            }
          }
          const grouped = _.groupBy(plane, (item) => {
            return [item.fields, item.operation, item.optional];
          });
          return grouped
        },
        simpleSearchOptionsFilter(){
          const filters = this.result.filters;
          let result = {
            match: [],
            notMatch: []
          }
          filters.forEach(filter=>{
            if (filter.conditions[0].operation === 'match_query'){
                result.match.push(filter.conditions[0].value)
            } else {
              result.notMatch.push(filter.conditions[0].value)
            }
          })
          return result
        },
        isMobile() {
          return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)
        }
    },
    methods: {

        canBeEdit(){
          if (this.permissions.includes('search.geo') || typeof this.result.location === 'undefined'){
            return true
          } else {
            return false
          }
        },


        makeDateFormat(date){
          if (moment(date, "YYYY-MM-DD", true).isValid()){
            let tempDate = date
            tempDate = moment(tempDate).format('DD.MM.YYYY')
            return tempDate
          }
          return date
        },
        async getSreenshot() {
          if(this.result.screenshotId && !this.screenshot) {
            const imageStorage = new ImageStorage('map', 'screenshots');
            imageStorage.init().then(() => {
              imageStorage.getImageById(this.result.screenshotId).then(image => {
                this.screenshot = image.imageData
              })
            })
          } 
        },

        editFullTextSearch(result){
          this.$store.dispatch('mainMenuState/menuParam', 'full_search').then(()=>{
            this.$store.dispatch('full_search/updateResultFilters', result)
            window.scrollTo(0,0);
            // this.$refs['edit-full-modal'].hide()
          })
        },

        
        

        ucFirst(str) {
          if (!str) return str;
          return str[0].toUpperCase() + str.slice(1);
        },
        deleteChild(el){
          let payload = {
            result: this.result,
            child: el
          }
          this.$store.dispatch('contacts/removeChild', payload)
              .then(() => {
                this.$store.dispatch('ui/getFreeSpaceInfo')
              });
          this.$emit('force');
        },
        stopSearch() {
          if(typeof this.result.controller !== 'undefined') {
            typeof this.result.controller.abort === 'function' && this.result.controller.abort();
            this.deleteSearchItem();
          }
        },
        hideModal() {
            this.$refs['delete-modal'].hide();
        },
        hideEdit(){
          this.$refs['edit-modal'].hide()
        },
        deleteSearchItem() {
            this.$store.dispatch('contacts/removeResult', { key: this.keyIndex, ...this.result })
                .then(() => {
                  this.$store.dispatch('ui/getFreeSpaceInfo')
                });
            this.$emit('force');
        },
        deleteModalExecute(executeName) {
            this[executeName]()
        },

        editModalExecute(value) {
          if(this.result.param === 'username') {
            if (value.substring(0, 1) === '@') {
              value = value.substring(1);
            }
          }
            this.result.value = value;
            this.$forceUpdate();
            const payloadArray = [
                'iterative_search',
                'offline',
                'service_types',
                'advaced'
            ]
            let payload = JSON.parse(this.payload)
            for (const indexArray of payloadArray) {
                if (typeof this.result[indexArray] !== 'undefined') {
                    payload[indexArray] = this.result[indexArray];
                } else {
                    payload[indexArray] = undefined;
                }
            }
            payload.param = this.result.param;
            payload.value = this.result.value;
            this.hideEdit();
            this.$store.dispatch('contacts/updateSearch', payload).then(() => {
            
            });
            this.$store.dispatch('contacts/updateSearchItem', {
                oldPayload: this.result,
                newValue: value
            });
            try {
              this.$matomo.trackEvent('Web Edit Request', 'WasClicked');
            } catch (e) {
              console.log('ignore error');
            }
        },

        onLoadImg(data) {
            this.imgBase = data;
            this.showLoader = false;
            if (data.fullLoad) {
                this.fullImageLoaded = data.fullLoad;
            }
        },
        updateFaces() {
            this.showLoader = true;
            let payload = {
                payload: this.result,
                face_select: this.result.face_select
            }
            if (typeof this.result.image_url !== 'undefined') {
                this.$store.dispatch('contacts/addFacesToSearchResults', payload).then(() => {
                    if (this.result.face_select.length > 1) {
                      this.isPhotoSegments = true;
                    }
                    this.setMeta()
                })
            }
        },
        openModal() {
            this.isPhotoSegments = true;
            // this.$refs['fragments'].show();
        },
      loadResult() {
        this.$store.dispatch('contacts/getResult', this.result.keyVal).then(data => {
          this.resultFromCache = data;
          this.getSreenshot();
        })
      },
      checkLengthFilter() {
        const parent = this.$refs.optionsFilters;
        if(!_.isUndefined(parent)) {
          for(const child of parent.children) {
            if (child.innerText.length > 21) {
              return true;
            }
          }
        }
        return false;
      },
      setMeta() {
        if (!_.isUndefined(this.result.image_url)) {
            this.meta = {
                children: [],
                key: this.result.keyVal,
                parent: null
            }
        }
      }
    },
    mounted() {
      this.getSreenshot();
      if (this.result.loaded){
        this.loadResult();
        this.photo = {
          date: this.result.date,
          face_select: this.result.face_select,
          image: this.result.image_url,
        }
      }
      if (typeof this.result.promise_file !== 'undefined' && typeof this.result.promise_file.then === 'function') {
          this.result.promise_file.then(() => {
            this.photo = {
              date: this.result.date,
              face_select: this.result.face_select,
              image: this.result.image_url,
            }
              this.$store.dispatch('contacts/addPhotoToSearchResult', this.photo)
              if (!this.result.children.length && typeof this.result.image_url !== 'undefined') {
                  this.updateFaces()
                  delete this.result.promise_file;
              }
          })
      }
      if((typeof this.result.controller !== 'undefined'
          && !this.result.loaded
          && typeof this.result.controller.abort === 'undefined') ||
          (typeof this.result.controller === 'undefined'
          && !this.result.loaded)
      ) {
        this.deleteSearchItem();
      }
      this.setMeta();
    },
    watch: {
      'result.image_url': {
        handler(val) {
        }
      },

        // 'result.loaded': function(value) {
        //     if (value && typeof this.result.image_url !== 'undefined'){
        //         this.meta = {
        //             children: [],
        //             key: this.result.keyVal,
        //             parent: null
        //         }
        //     }
        // },
        // isPhotoSegments(value) {
        //     if(value) {
        //         this.$refs['fragments'].show()
        //     }
        // }
    }
}
</script>
<style >

.optionsWidth{
  width: 18rem;
  max-height: 68px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-item-photo img {
    width: 64px;
    height: 64px;
    object-fit: contain;
}
.padding {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.result-item {
    cursor: pointer;
}

.spaceholder {
    display: inline-block;
    width: 16px;
    margin-right: 15px;
}

td {
    vertical-align: middle;
}

.hidden-label {
    display: none;
}
@media (max-width: 1300px) {
  .optionsWidth{
    max-width: 10rem;
    max-height: 68px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 992px) {
  .optionsWidth{
    max-width: 9rem;
    max-height: 68px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
    tr.result-item, tr.child-result {
        display: block;
        border-bottom: 1px solid #ccc;
        width: 100%;
    }
    .table tr.result-item td, .table tr.child-result td {
        display: block;
        padding-top: 5px !important;
        padding-bottom: 0 !important;
        border: 0;
        width: 100%;
    }
    .hidden-label {
        display: block;
        font-weight: 700;
        margin-bottom: 0;
    }
    h6 {
        margin-bottom: 0 !important;
    }
} 
h6 small {
    font-size: 0.75em;
}
.map-preview {
  max-width: 100%;
}
@media (min-width: 640px) {
  .tooltip-with-map {
    width: 600px;
  }
}
.result-item-photo-container {
  position: relative;
}
.result-item-photo-container .badge {
  position: absolute;
  bottom: -2px;
  right: -2px;
}
</style>
<style lang="scss">
  .editIconColor{
    color: #4099ff !important;
  }
  .primaryIcon{
    cursor: pointer;
    color: #4099ff !important;
  }
  .modal-header{
    align-items: baseline !important;
  }


</style>