import { isUndefined, isArray } from "underscore";
export default  function (obj, item) {
    let nameArr = [];
    let countResult = 0;
    if(obj.some(item => !!item.search_results)){
        for (let objItem of obj) {
            
            if(!isUndefined(objItem.matches_count)) {
                countResult += objItem.matches_count;
            }
            if(isArray(objItem.search_results)) {
                for(let res of objItem.search_results) {
                    if(!isUndefined(res.photos)) {
                        countResult += res.photos.length
                    }
                    if (res.count) {
                        countResult = res.count;
                    }

                    if (res.data) {
                        let nameFilter = res.data.data.filter((el) => {
                            return ['name', 'first_name'].includes(el.param);
                        })
                        nameArr.push(...nameFilter);
                    }
                }
            }

        }
    }
    if(isUndefined(item.initial)) {
        let temp = []
        let totalCost = 0;
        obj.forEach(result =>{
            if (typeof result !== "undefined" && !result.error  ){
                totalCost += result.cost;
                temp.push({
                    source: result.source,
                    source_locales: result.source_locales,
                    cost: result.cost,
                    icon: result.icon
                })
            }
        })
        temp = temp.sort(function (a, b){ return b.cost - a.cost })
        item.cost = temp;
        item.totalCost = totalCost;
    }
    item.countResult = countResult;
    item.countSources = obj[0].results
    if(nameArr.length) {
        item.name = nameArr.map(nameVal => nameVal.value);
    } else {
        item.name = 'No name'
    }
    if(obj[0].error) {
        item.error = obj[0].error
        item.errorName = obj[0].errorName
    } else {
        item.error = false
    }
    if (typeof obj[0].face_select !== 'undefined' && typeof obj[0].face_select.face_boxes.length){
        item.face_select = obj[0].face_select;
    }
    item.initial = true
}