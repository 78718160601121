import axios from "../../configs/axios";
import clearFilter from "../../helpers/clearFilter";

export default {
    namespaced: true,
    state: {
        usersList: [],
        userSelected: {},
        lastPayload: {},
        userDetail: {},
        roles: [],
        permissions: [],
        payload: {
            order_by: 'created_at',
            order_type: 'DESC',
            filters: {
                search: "",
                created_from: undefined,
                created_to: undefined,
            },
            limit: 300,
            offset: 0
        },
        totalUsers: 0
    },
    getters: {
        users: state => state.usersList || [],
        user: state => state.userDetail,
        roles: state => state.roles,
        userLogins: state => state.usersList.map(user => user.login),
        payload: state => state.payload,
        permissions: state => state.permissions,
        totalUsers: state => state.totalUsers,
    },
    actions: {
        getUsersList({state, commit}, payload) {
            const data = clearFilter(payload);
            const addToList = payload.addToList ? true : false;
            delete data.addToList;
            return new Promise((resolve, reject) => {
                axios({url: 'admin/users', data, method: 'POST'})
                    .then((resp) => {
                        let users = resp.data.data;
                        if(addToList) {
                          users = state.usersList.concat(resp.data.data);
                        } 
                        commit('setUsersList', users);
                        commit('setLastPayload', data);
                        commit('setTotalUsers', resp.data.total);
                        resolve(resp.data.data);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        storeUser({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'admin/user', data: payload, method: 'POST'})
                    .then(resp => {
                        commit('setUserToList', resp.data);
                        resolve(resp.data)
                    })
                    .catch(err => {
                    reject(err)
                })
            })
        },
        updateUser({commit}, payload) {
            return new Promise((resolve, reject) => {
                let id = payload.id;
                axios({url: `admin/user/${id}`, data: payload, method: 'PUT'})
                    .then(resp => {
                        commit('setUpdatedUser', resp.data);
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        },
        deleteUser({dispatch, state}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: `admin/user/${payload.id}`, method: 'DELETE'})
                    .then(resp => {
                        dispatch('getUsersList', state.lastPayload);
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        },
        userSelect({commit}, id) {
            commit('setUserSelected', id);
        },
        getUser({commit}, id) {
            return new Promise((resolve, reject) => {
                axios({url: `admin/user/${id}`, method: "GET"})
                    .then(resp => {
                        resolve(resp.data);
                        commit('setUser', resp.data)
                    })
                    .catch(err => reject(err))
            })
        },
        getRoles({commit}, payload) {
            const data = (() => {
                if(payload.parentId) {
                    return {
                        filters: {
                            user_id: payload.parentId
                        }
                    };
                }
                return {};
            })()
            return new Promise((resolve, reject) => {
                axios({url: `admin/roles`, data, method: "POST"})
                    .then(resp => {
                        resolve(resp.data);
                        commit('setRoles', resp.data.data)
                    })
                    .catch(err => reject(err))
            })
        },
        getPermissions({commit}, payload) {
            const data = (() => {
                if(payload.parentId) {
                    return {
                        filters: {
                            user_id: payload.parentId
                        }
                    };
                }
                return {};
            })()
            return new Promise((resolve) => {
                axios({url: `admin/permissions`, data, method: "POST"})
                    .then(resp => {
                        resolve(resp.data);
                        commit('setPermissions', resp.data.data)
                    })
            })
        },
        payloadProcess({commit}, payload) {
            commit('setPayload', payload);
        },
        clearUsersList({commit}) {
            commit('setCleanUsers');
        }
    },
    mutations: {
        setUsersList(state, payload) {
            state.usersList = payload;
        },
        setUserToList(state, payload) {
            state.usersList.push(payload);
        },
        setUpdatedUser(state, payload) {
            state.usersList.push(payload);
        },
        setUserSelected(state, id) {
          let users = state.usersList.filter(item => item.id === id);
          state.userSelected = users[0];
        },
        setLastPayload(state, payload) {
            state.lastPayload = payload;
        },
        setUser(state, payload) {
            state.userDetail = payload
        },
        setRoles(state, payload) {
            state.roles = payload
        },
        setPayload(state, payload) {
            state.payload = payload;
        },
        setCleanUsers(state) {
            state.usersList = [];
        },
        setTotalUsers(state, payload) {
            state.totalUsers = payload;
        },
        setPermissions(state, payload) {
            state.permissions = payload
        }
    }
}