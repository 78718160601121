import axios from "@/configs/axios";
import { reject } from "underscore";

export default {
    namespaced: true,

    state: {
        groups: [],
        createdSourceGroup: null,
        updatedSourceGroup: null,
        lastDeletedSourceGroup: null,
        detailedSourceGroup: {
            sources_ids: [],
            users_ids: []
        },
        sources: [],
        meta: {
            sources: {total: 0, without_groups: 0},
            users: {total: 0, without_groups: 0}
        },
        groupsTotal: 0,
        totalSources: 0
    },
    getters: {
        groups: state => state.groups,
        detailedSourceGroup: state => state.detailedSourceGroup,
        sources: state => state.sources,
        totalSources: state => state.totalSources,
        meta: state => state.meta,
        groupsTotal: state => state.groupsTotal
    },
    actions: {
        getGroupList({commit}, payload) {
            const data = (() => {
                if(payload.parentId) {
                    payload['filters'] ={
                        owner_id: payload.parentId
                    }
                }
                return payload;
            })()
            return new Promise((resolve, reject) => {
                axios({method: 'post', url: 'admin/sources_groups', data})
                    .then(resp => {
                        commit('setSourceGroups', resp.data.data);
                        commit('setMeta', resp.data.meta);
                        commit('setGroupsTotal', resp.data.total);
                        resolve(resp.data);
                    })
                    .catch(e => {
                        reject(e);
                    })
            })
        },
        createSourceGroup({commit}, data) {
            return new Promise((resolve, reject) => {
                axios({method: 'post', url: 'admin/source_group', data})
                    .then(resp => {
                        resolve(resp.data)
                        commit('setCreatedSourceGroup', resp.data)
                    }).catch(e => reject(e))
            })
        },
        updateSourceGroup({commit}, data) {
            return new Promise(resolve => {
                axios({method: 'put', url: `admin/source_group/${data.id}`, data})
                    .then(resp => {
                        resolve(resp.data)
                        commit('setUpdatedSourceGroup', resp.data)
                    })
            })
        },
        getSourceGroup({commit}, data) {
            return new Promise(resolve => {
                axios({method: 'get', url: `admin/source_group/${data}`})
                    .then(resp => {
                        resolve(resp.data)
                        commit('setDetailSourceGroup', resp.data)
                    })
            })
        },
        deleteSourceGroup({commit}, data) {
            return new Promise(resolve => {
                axios({method: 'delete', url: `admin/source_group/${data.id}`})
                    .then(() => {
                        resolve(true);
                        commit('setDeletedSourceGroup', data.id);
                    })
            })
        },
        clearObjectDetail({commit}) {
            commit('setClearDetail');
        },
        getSources({commit}, data = {}) {
            return new Promise(resolve => {
                axios({method: 'post', url: 'admin/sources', data})
                    .then(resp => {
                        commit('setSources', resp.data)
                        resolve(resp.data)
                    })
            })
        },
        clearSources({commit}) {
            commit('setCleanSources');
        },
        changeOrderSorces({commit}, payload) {
            commit('setOrderSources', payload);
        },
    },
    mutations: {
        setSourceGroups(state, groups) {
            state.groups = groups
        },
        setMeta(state, payload) {
            state.meta = payload;
        },
        setCreatedSourceGroup(state, payload) {
            state.createdSourceGroup = payload;
        },
        setUpdatedSourceGroup(state, payload) {
            state.updatedSourceGroup = payload;
        },
        setDeletedSourceGroup(state, id) {
            state.lastDeletedSourceGroup = id;
        },
        setDetailSourceGroup(state, payload) {
            payload.users_ids = payload.users ? payload.users.map(item => item.id) : undefined;
            payload.sources_ids = payload.sources ? payload.sources.map(item => item.id) : undefined;
            state.detailedSourceGroup = payload;
        },
        setClearDetail(state) {
            state.detailedSourceGroup = {}
        },
        setSources(state, payload){
            state.sources = payload.data;
            state.totalSources = payload.total;
        },
        setCleanSources(state) {
            state.sources = [];
        },
        setOrderSources(state, payload) {
            state.sources.sort((a, b) => {
                let order;
                switch(payload) {
                    case 'up_name':
                        order = a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
                        return order;
                    case 'down_name':
                        order = a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1;
                        return order;
                    case 'up_date':
                        return a.released_at - b.released_at;
                    case 'down_date':
                        return b.released_at - a.released_at; 
                }
            });
        },
        setGroupsTotal(state, payload) {
            state.groupsTotal = payload;
        }
    }
}