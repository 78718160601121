import axios from "../../configs/axios";
import clearFilter from "@/helpers/clearFilter";

export default {
    namespaced: true,
    state: {
        clientTransactions: [],
        companyTransactions: [],
        currentUserList: [],
        totalUsers: 0,
    },
    getters: {
        clientTransactions: state => state.clientTransactions,
        companyTransactions: state => state.companyTransactions,
        currentUserList: state => state.currentUserList,
        totalUsers: state => state.totalUsers,
    },
    actions: {
        getClientTransactions({commit}, payload) {
            return new Promise((resolve, reject) => {
            axios({url: 'client_transactions', data: payload, method: 'POST' })
                .then(resp => {
                    commit('setClientTransactions',resp.data)
                    resolve(resp);
                })
                .catch(err => {

                    reject(err)
                })

            })
        },
        getCompanyTransactions({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'company_transactions', data: payload, method: 'POST' })
                    .then(resp => {
                        if (resp.data.total > payload.limit){
                            let innerPayload = payload;
                            commit('setCompanyTransactions', resp.data)
                            for (let i = payload.limit ; i < resp.data.total; i+= payload.limit){
                                innerPayload.offset = i;
                                axios({url: 'company_transactions', data: innerPayload, method: 'POST' })
                                    .then((resp) => {
                                        commit('setCompanyTransactionsPart', resp.data);
                                    })
                            }
                        } else {
                            commit('setCompanyTransactions',resp.data)
                        }
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('clean');
                        reject(err)
                    })

            })
        },
        getUserList({commit}, payload){
            return new Promise((resolve, reject) => {
                axios({url: 'admin/users', data: payload, method: 'POST'})
                    .then((resp) => {
                        commit('setUsersList', resp.data);
                        resolve(resp.data.data);
                    })
                    .catch(err => {
                        commit('clean');
                        reject(err)
                    })
            })
        },
        cleanTransactions({commit}){
            commit('clean');
        },
    },
    mutations: {
        setUsersList(state, userList){
            state.currentUserList = userList.data;
            state.totalUsers = userList.total;
        },
        setClientTransactions(state , clientTransactions){
            state.clientTransactions = clientTransactions;
        },
        setCompanyTransactions(state , companyTransactions){
            state.companyTransactions = companyTransactions;
        },
        setCompanyTransactionsPart(state , companyTransactions){
            let temp = []
            companyTransactions.data.forEach(item=> {
                if (state.companyTransactions.data.findIndex(dataItem => dataItem.created_at === item.created_at) < 0){
                    temp.push(item)
                }
            })
            state.companyTransactions.data = state.companyTransactions.data.concat(temp);
        },
        clean(state){
            state.clientTransactions = []
            state.companyTransactions = []
        },
    }
}

