import axios from "@/configs/axios";
import env from '../../configs/env.json';

let host = '';
if(location.hostname.includes('web')) {
    host = location.hostname.replace('web.', '')
    env.monitoringEndpoint = `https://monitoring.${host}/api`
}

const domain = env.monitoringEndpoint;

export default {
    namespaced: true,
    state: {
        groups: [],
        createdServiceGroup: null,
        updatedServiceGroup: null,
        lastDeletedServiceGroup: null,
        detailedServicesGroup: {
            services_ids: [],
            users_ids: []
        },
        services: [],
        meta: {
            services: {total: 0, without_groups: 0},
            users: {total: 0, without_groups: 0}
        },
        groupsTotal: 0,
        customerServices: []
    },
    getters: {
        groups: state => state.groups,
        detailedServicesGroup: state => state.detailedServicesGroup,
        services: state => state.services,
        meta: state => state.meta,
        customerServices: state =>  state.customerServices.length ? state.customerServices : 
            localStorage.getItem('customerServices') ? JSON.parse(localStorage.getItem('customerServices')) : [],
        groupsTotal: state => state.groupsTotal
    },
    actions: {
        getGroupList({commit}, payload) {
            const data = (() => {
                if(payload.parentId) {
                    payload['filters'] = {
                        owner_id: payload.parentId
                    }
                }
                return payload;
            })()
            return new Promise((resolve, reject) => {
                axios({method: 'post', url: 'admin/services_groups', baseURL: domain, data})
                    .then(resp => {
                        commit('setGroups', resp.data.data);
                        commit('setMeta', resp.data.meta);
                        commit('setGroupsTotal', resp.data.total);
                        resolve(resp.data);
                    })
                    .catch(e => {
                        reject(e);
                    })
            })
        },
        createServiceGroup({commit}, data) {
            return new Promise((resolve, reject) => {
                axios({baseURL: domain, method: 'post', url: 'admin/service_group', data})
                    .then(resp => {
                        resolve(resp.data)
                        commit('setCreatedServiceGroup', resp.data)
                    }).catch(e => reject(e))
            })
        },
        updateServiceGroup({commit}, data) {
            return new Promise((resolve, reject) => {
                axios({baseURL: domain, method: 'put', url: `admin/service_group/${data.id}`, data})
                    .then(resp => {
                        resolve(resp.data)
                        commit('setUpdatedServiceGroup', resp.data)
                    }).catch(e => reject(e))
            })
        },
        deleteServiceGroup({commit}, data) {
            return new Promise(resolve => {
                axios({baseURL: domain, method: 'delete', url: `admin/service_group/${data.id}`})
                    .then(() => {
                        resolve(true);
                        commit('setDeletedServiceGroup', data.id);
                    })
            })
        },
        clearObjectDetail({commit}) {
            commit('setClearDetail');
        },
        getServices({commit}, data = {}) {
            return new Promise(resolve => {
                axios({method: 'post', url: 'admin/services', baseURL: domain, data})
                    .then(resp => {
                        commit('setServices', resp.data.data)
                        resolve(resp.data)
                    })
            })
        },
        getCustomerServices({commit}, data = {}) {
            return new Promise(resolve => {
                axios({method: 'post', url: 'services', baseURL: domain, data})
                    .then(resp => {
                        commit('setCustomerServices', resp.data.data)
                        localStorage.setItem('customerServices', JSON.stringify(resp.data.data));
                        resolve(resp.data)
                    }).catch(e => {
                        resolve({data: []})
                        localStorage.setItem('customerServices', JSON.stringify([]));
                        commit('setCustomerServices', [])
                    })
            })
        },
        clearServices({commit}) {
            commit('setCleanServices');
        },
        getServiceGroup({commit}, data) {
            return new Promise(resolve => {
                axios({method: 'get', baseURL: domain, url: `admin/service_group/${data}`})
                    .then(resp => {
                        resolve(resp.data)
                        commit('setDetailServiceGroup', resp.data)
                    })
            })
        },
        changeOrderServices({commit}, payload) {
            commit('setOrderServices', payload);
        },
    },
    mutations: {
        setGroups(state, groups) {
            state.groups = groups
        },
        setMeta(state, payload) {
            state.meta = payload;
        },
        setCreatedServiceGroup(state, payload) {
            state.createdServiceGroup = payload;
        },
        setUpdatedServiceGroup(state, payload) {
            state.updatedServiceGroup = payload;
        },
        setDeletedServiceGroup(state, id) {
            state.lastDeletedServiceGroup = id;
        },
        setDetailServiceGroup(state, payload){
            payload.users_ids = payload.users ? payload.users.map(item => item.id) : undefined;
            payload.services_ids = payload.services ? payload.services.map(item => item.id) : undefined;
            state.detailedServicesGroup = payload;
        },
        setClearDetail(state) {
            state.detailedServicesGroup = {}
        },
        setServices(state, payload) {
            state.services = payload;
        },
        setCleanServices(state) {
            state.services = [];
        },
        setOrderServices(state, payload){
            state.services.sort((a, b) => {
                let order;
                switch(payload) {
                    case 'up_name':
                        order = a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        return order;
                    case 'down_name':
                        order = a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
                        return order;
                }
            });
        },
        setGroupsTotal(state, payload) {
            state.groupsTotal = payload;
        },
        setCustomerServices(state, payload) {
            state.customerServices = payload;
        },
    },
    
}