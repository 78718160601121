<template>
  <b-card no-body class="mb-1" v-if="toShow || level === 1">
    <b-card-header v-if="meta.payload.param !=='full_search'" :id="item.value" header-tag="header"   v-b-toggle="item.value" class="p-1" role="tab">
      <h2 class="level-title" style="text-align: center; margin-bottom: 0">{{ $t('result.stage') }} {{item.level}}: {{ $t('result.search_by') }} {{item.value}}</h2>
    </b-card-header>
    <b-collapse :id="item.value" :visible="resultVisible"  role="tabpanel">
              <unused-services v-if="finalServices.length || errorServices.length"
                               :services="finalServices"
                               :errors="errorServices" />
      <div v-for="(result,index) in item.items"  :key="index" class="card-content" :id="`contact_${index}`">
        <div v-if="result.search_results">
          <Contact :result="result"
                   v-if="result.source !== 'TelegramGroupScan'"
                   :item="item"
                   :parentSources="item.parentSources"
                   :key="index"
                   :index="index"
                   :meta="meta"></Contact>
          <contact-group-scan v-else
                              :result="result"
                              :item="item"
                              :parentSources="item.parentSources"
                              :key="index"
                              :index="index"
                              :meta="meta"/>
        </div>

      </div>
    </b-collapse>
  </b-card>
</template>

<script>
import UnusedServices from "@/components/results/UnusedServices.vue";
import Contact from "@/components/Contact.vue";
import {mapGetters} from "vuex";
import _ from "underscore";
import ContactGroupScan from "@/components/ContactGroupScan.vue";
export default {
  name: "Stage",
  data() {
    return {
      toShow: true
    }
  },
  components: {
    ContactGroupScan,
    UnusedServices,
    Contact
  },
  props: ['item', 'meta', 'errors', 'resultVisible', 'level'],
  computed: {
    ...mapGetters('ui', ['services']),
    sources() {
      return this.item.items.filter(result => !result.search_results && result.success).map(result => result.source)
    },
    finalServices() {
      return this.services.filter(result => this.sources.includes(result.name));
    },
    errorServices() {
      const services = this.item.items.filter(result => !result.search_results && !result.success)
          .map(result => result.source)
      return this.services.filter(result => services.includes(result.name));
    },
  },
  methods: {
    isNotEmpty(item) {
      const resultArray = [];
      if(_.isArray(item.items)) {
        for (const itemInside of item.items) {
          if(itemInside.search_results) {
            resultArray.push(1)
          }
        }
      }
      return !!resultArray.length;
    }
  },
  mounted() {
    this.toShow = this.isNotEmpty(this.item);
  }
}
</script>

<style lang="scss" scoped>
.card {
  padding: 0.7em;
  .card-header {
    padding: 0.5em !important;
  }
}

.level-title{
  font-size: 1.25em;
  color: #363636;
  font-weight: 600;
  line-height: 1.125;

  @media (max-width: 992px) {
    font-size: 1em;
  }
}
.highlighted{
  animation: colorChange 700ms;
}

@keyframes colorChange {
  0% {
    background-color: #4099ff;
  }
  100%{
    background-color: #fbfbfb;
  }
}

.card-content {
  scroll-margin-top: 100px;
}

</style>