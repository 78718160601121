import _ from "underscore";
export default {
    methods: {
        afterPhotoSearch(data, payload) {
            const user = JSON.parse(localStorage.getItem('user')).login;
            let type = '';
            if (data.results) {
                type = 'results';
            } else if(data.face_select) {
                type = 'faceSelect';
            }
            this.type = type;
            this.loading = false;
            let results;

            let count = 0;
            const meta = !_.isUndefined(this.meta) ? JSON.parse(JSON.stringify(this.meta)) : {}
            meta.payload = payload;
            data.payload = payload;
            if (typeof data.results !== 'undefined'){
                data.results.forEach(item => {
                    if (item.search_results){
                        count += item.search_results.length
                    }
                })
                this.photo.results = count;
            }
            data.faceResults = results;
            if(type === 'faceSelect') {
                this.showLoader = false;
                this.isPhotoSegments = true;
                this.photo.face_select = data.face_select;
            } else if(type === 'results') {
                let count = 0;
                data.results.forEach(item => {
                    if(item.search_results) {
                        item.search_results.forEach(result=>{
                            if(!_.isUndefined(result.photos)) {
                                count += result.photos.length
                            } else {
                                count++;
                            }
                        })
                    }
                })
                this.photo.results = count;

                if(data.results.length) {
                    this.photo.childKey = `image_${data.payload.value}_${user}`
                }
                this.$store.dispatch('contacts/cacheWithResults', {
                    data,
                    meta,
                    payload,
                    type
                });
            }
        },
        afterFragmentSearch(face, data) {
            const user = JSON.parse(localStorage.getItem('user')).login;
            if(typeof this.photo.results === 'undefined') {
                this.photo.results = 0;
            }
            let results;
            let count = 0;
            const meta = !_.isUndefined(this.meta) ? JSON.parse(JSON.stringify(this.meta)) : {}
            meta.payload = data.payload;
            data.results.forEach(item => {
                if(item.total_count) {
                    count += item.total_count;
                }
            })
            results = count;
            if(results) {
                this.photo.face_select[face.index].childKey = `image_${data.payload.value}_${user}`;
                face.childKey = `image_${data.payload.value}_${user}`;
            }
            if (!this.fromMainSearch){
                data.face_select = {
                    face_boxes: [],
                    width: this.photo.face_select.width,
                    height: this.photo.face_select.height
                };
                data.face_select.face_boxes.push(face);
                this.photo.results+=parseInt(results);
                this.photo.face_select.face_boxes[face.index].results = results;
            } else {
                data.face_select = {
                    face_boxes: [],
                    width: this.photo.face_select.width,
                    height: this.photo.face_select.height
                };
                data.face_select.face_boxes.push(face);
                this.photo.results+=parseInt(results);
                this.photo.face_select[face.index].results = results;
            }
            face.results = results;
            if (this.fromMainSearch){
                data.faceResults = results;
                let payload = {
                    payload: this.searchResult,
                    face: face
                }
                this.$store.dispatch('contacts/addFacesSearchCount', payload)
                this.$store.dispatch('contacts/cacheWithResults', {data, meta});
            } else {
                this.$store.dispatch('contacts/cacheWithResults', {data, meta});
            }
        }
    }
}