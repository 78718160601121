<template>
    <div class="page-header card  header-inside">
        <div class="row align-items-end justify-content-md-start" v-if="!['Login', 'VerificationAdd', 'verification'].includes($route.name)" style="margin-left: 0">
            <div class="col-12 col-lg-8">
                <div class="page-header-title justify-content-md-start"  v-show="['Result', 'Child', 'Photo'].includes($route.name) && typeof currentMeta !== 'undefined' || !['Result', 'Child', 'Photo'].includes($route.name)" :class="{'without-info': !showAddition}">
                    <i class="feather bg-c-blue icon-layout d-none d-lg-inline-flex"></i>
                    <div class="d-inline">
                      <div class="d-flex">
                        <h5 class="header-heading" style="display: flex">
                          {{ $t('pages.'+$route.name) }}
                          <span v-if="$route.name === 'UserDetail'">&nbsp;- {{ user.login }}</span>
                          <span v-if="showAddition && $route.name !== 'Child' && this.$route.name !== 'Photo'">
                            <span v-if="typeof meta.payload !== 'undefined' && meta.payload.param !== 'full_search'">: {{meta.payload.value}} </span>
                            <span v-else>: </span>
                          </span>
                          <span v-if="$route.name === 'Child'">{{$t("search_list_item.file")}}</span>

                        </h5>
                        <!-- <search-cost
                            class="coin"
                            style="margin-left: 20px;margin-top: 0;height: 24px"
                            :header="header"
                            v-if="$route.name === 'Child'|| 'Result'"
                            :cost="meta.cost"
                            :total-cost="meta.totalCost"
                            :ident="meta.key"
                        /> -->
                      </div>
                        <span class="options" style="font-size: 1em; font-weight: 600;display: flex;justify-content: flex-start;" v-if="showAddition && typeof meta.payload !== 'undefined' && meta.payload.param !== 'full_search'">
                          <span>{{ $t("search_list_item.options") }}:&nbsp;</span>
                          <span v-for="(item, k) in visualOptions" :key="k">{{ $t(`visualOptions.${item}`) }}{{ k === visualOptions.length - 1 ? '' : ',&nbsp;' }}</span>
                          <span class="hesDod" style="display: flex" v-if="this.$route.name === 'Child' ||
                             (typeof meta.secondarySearch !=='undefined'&& meta.secondarySearch) ||
                             (typeof meta.secondarySearch !=='undefined' && meta.payload.secondarySearch)">. {{$t("search_list_item.child_search")}}
                          </span>

                        </span>
                        <div class="options text-muted" style="font-size: 1em; font-weight: 600;display: flex;justify-content: flex-start; z-index: 2; position: relative"
                             v-else-if="showAddition && typeof meta.payload !== 'undefined' && meta.payload.param === 'full_search' && typeof meta.payload.filters !=='undefined' && typeof meta.payload.filters[0].fields ==='undefined' ">
                          <div style="display:flex;">
                            <div class="optionsWidthHeader">
                              <div>
<!--                                 <span v-if="searchOptionsFilter(meta.payload.filters).match.length">{{ucFirst($t('simpleSearchListItem.match_query'))}}: </span>-->
                                 <span>{{searchOptionsFilter(meta.payload.filters).match.map(item=> item.replace(/,*$/, '')).join(', ')}}</span>
                              </div>

                              <div>
                                <span v-if="searchOptionsFilter(meta.payload.filters).notMatch.length">{{ucFirst($t('simpleSearchListItem.not_match_query'))}} </span>
                                <span >{{searchOptionsFilter(meta.payload.filters).notMatch.map(item=> item.replace(/,*$/, '')).join(', ')}}</span>
                              </div>
                            </div>

                            <span v-if="typeof meta.payload !== 'undefined' && typeof meta.payload.filters !== 'undefined' && meta.payload.filters.length > 3" style="display:flex; justify-content: center; align-items: center; margin-left: 1rem;">
                             <tippy
                                 interactive
                                 :animate-fill="false"
                                 placement="bottom"
                                 distant="7"
                                 theme="light"
                                 animation="shift-toward"
                                 trigger="mouseenter"
                                 offset="0, 20"
                                 arrow>
                                <template v-slot:trigger>
                                  <i class="feather icon-info primaryIcon" style="width: auto; height: auto; box-shadow: none; margin-right: 0 "  data-bs-toggle="dropdown" aria-expanded="false"  @click.stop ></i>
                                </template>
                                <span>
                                  <span style="display:flex;">
                                      <span>
                                        <div>
<!--                                           <span v-if="searchOptionsFilter(meta.payload.filters).match.length">{{ucFirst($t('simpleSearchListItem.match_query'))}}: </span>-->
                                           <span>{{searchOptionsFilter(meta.payload.filters).match.map(item=> item.replace(/,*$/, '')).join(', ')}}</span>
                                        </div>

                                        <div>
                                          <span v-if="searchOptionsFilter(meta.payload.filters).notMatch.length">{{ucFirst($t('simpleSearchListItem.not_match_query'))}}: </span>
                                          <span >{{searchOptionsFilter(meta.payload.filters).notMatch.map(item=> item.replace(/,*$/, '')).join(', ')}}</span>
                                        </div>
                                      </span>
                                   </span>
                                </span>
                              </tippy>
                            </span>
                          </div>
                        </div>
                        <div v-else-if="showAddition && typeof meta.payload !== 'undefined' && meta.payload.param === 'full_search' && ((typeof meta.payload.filters !=='undefined' && typeof meta.payload.filters[0].fields !=='undefined') || typeof meta.payload.location !== 'undefined')" class="options text-muted"
                             style="font-size: 1em; font-weight: 600;display: flex;justify-content: flex-start; z-index: 2; position: relative">
                            <span style="display:flex;">
                              <span class="text-muted">
                                <div class="optionsWidthHeader">
                                  <div class="" style="display: flex;justify-content: flex-start; z-index: 2; position: relative"
                                       v-if="typeof meta.payload !== 'undefined' && typeof meta.payload.location !== 'undefined' &&  meta.payload.location.length">{{$t('params.geo_search')}}: {{ $t('geo.' + meta.payload.location[0].type) }}</div>

                                  <div v-if="typeof meta.payload !== 'undefined' && typeof meta.payload.filters !=='undefined' && typeof meta.payload.filters[0].fields !=='undefined'">
                                    <div v-for="obk of Object.keys(fullSearchOptionsFilter( meta.payload.filters))">
                                      <span v-if="typeof fullSearchOptionsFilter(meta.payload.filters)[obk][0].fields !=='undefined'">
                                        {{fullSearchOptionsFilter(meta.payload.filters)[obk][0].fields.map((it) => $t('params.' + it)).join(", ") | capitalize }}</span>{{
                                                                            $t(`fullTextSearchListItem.${fullSearchOptionsFilter( meta.payload.filters)[obk][0].optional}`)
                                                                          }}{{$t(`fullTextSearchListItem.${fullSearchOptionsFilter( meta.payload.filters)[obk][0].operation}`)}}
                                      {{ fullSearchOptionsFilter(meta.payload.filters)[obk].map(it => makeDateFormat(it.value)).join(', ')}}
                                    </div>
                                  </div>

                                </div>
                              </span>
                              <span style="display:flex; justify-content: center; align-items: center; margin-left: 1rem;" v-if="typeof meta.payload.filters !=='undefined' && typeof meta.payload.filters[0].fields !=='undefined'">
                                 <tippy
                                     interactive
                                     :animate-fill="false"
                                     placement="bottom"
                                     distant="7"
                                     theme="light"
                                     animation="shift-toward"
                                     trigger="mouseenter"
                                     offset="0,20"
                                     arrow>
                                  <template v-slot:trigger>
                                    <i class="feather icon-info primaryIcon" style="width: auto; height: auto; box-shadow: none; margin-right: 0 "  data-bs-toggle="dropdown" aria-expanded="false"  @click.stop ></i>
                                  </template>

                                  <span>
                                    <span>
                                    <div style="display: flex;justify-content: center; z-index: 2; position: relative"
                                         v-if="typeof meta.payload !== 'undefined' && typeof meta.payload.location !== 'undefined' && meta.payload.location.length">{{$t('params.geo_search')}}: {{ $t('geo.' + meta.payload.location[0].type) }}</div>
                                      <div v-for="obk of Object.keys(fullSearchOptionsFilter(meta.payload.filters))">
                                            <span v-if="typeof fullSearchOptionsFilter(meta.payload.filters)[obk][0].fields !=='undefined'">
                                            {{fullSearchOptionsFilter(meta.payload.filters)[obk][0].fields.map((it) => $t('params.' + it)).join(", ") | capitalize }}</span>{{
                                              $t(`fullTextSearchListItem.${fullSearchOptionsFilter( meta.payload.filters)[obk][0].optional}`)
                                            }}{{$t(`fullTextSearchListItem.${fullSearchOptionsFilter( meta.payload.filters)[obk][0].operation}`)}}
                                            {{ fullSearchOptionsFilter(meta.payload.filters)[obk].map(it => makeDateFormat(it.value)).join(', ')}}
                                      </div>
                                     </span>

                                  </span>
                                </tippy>
                              </span>
                            </span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div v-else class="align-items-center p-l-15 p-r-15">
          <div class="page-header-title d-flex justify-content-center" v-show="$route.name" :class="{'without-info': !showAddition}">
            <i class="feather bg-c-blue icon-layout d-lg-inline-flex"></i>
            <div class="d-inline">
              <h5 class="header-heading"><span>{{ $t('pages.'+$route.name) }}</span></h5>
            </div>
          </div>
        </div>
    </div>

</template>
<script>
import {mapGetters} from "vuex";
import _ from "underscore";
import SearchCost from "@/components/billing/searchCost";
import { tippy } from "vue-tippy";
import filters from "@/mixins/filters";
import moment from "moment";
import store from "../../store";

export default {
  components: {SearchCost},
  mixins: [filters],
  props:['cost', 'totalCost'],
  data() {
    return {
      header: true,
      currentMeta : undefined,
    }
  },
  watch: {
    meta(){
      this.currentMeta = this.meta;
    },
  },
  beforeDestroy() {
    this.currentMeta = undefined;
  },
  computed: {
    store() {
      return store
    },
      ...mapGetters('users', ['user']),
        showAddition() {
            return  this.$route.name === 'Result' || this.$route.name === 'Child' || this.$route.name === 'Photo';
        },
    ...mapGetters('contacts', ['meta']),
        // meta() {
        //     if (this.$route.name === 'Result' || this.$route.name === 'Photo') {
        //         return this.$store.state.contacts.results.meta;
        //     } else if (this.$route.name === 'Child') {
        //       return this.$store.state.contacts.childResults.meta;
        //     }
        //     return {}
        // },
        visualOptions: {
            get: function () {
                if(typeof this.meta.payload === 'undefined') {
                    return []
                }
                const keys = Object.keys(this.meta.payload);
                const options = [];
                for (const key of keys) {
                    if(typeof this.meta.payload[key] !== 'undefined') {
                      if(key !== 'service_options') {
                        options.push(`${key}_${this.meta.payload[key]}`);
                      } else {
                        if(!_.isUndefined(this.meta.payload[key].GetContact)) {
                            options.push(`get_contact_${this.meta.payload[key].GetContact.request_options.tags}`);
                        }
                      }
                    }
                }
                return options.filter(item => [
                    'advanced_false',
                    'offline_true',
                    'offline_false',
                    // 'service_types_online',
                    'service_types_hlr',
                    'advanced_true',
                    'iterative_search_true',
                    'iterative_search_false',
                    'service_types_online,hlr',
                    'online_search_enabled_true',
                    'cache_search_enabled_true',
                    'get_contact_true',
                    'services_Locator',
                    'service_types_hlr,online'].includes(item)).filter(item => {
                      return true;
                    });
            }

        },
    },
    methods:{
      makeDateFormat(date){
        if (moment(date, "YYYY-MM-DD", true).isValid()){
          let tempDate = date
          tempDate = moment(tempDate).format('DD.MM.YYYY')
          return tempDate
        }
        return date
      },
      fullSearchOptionsFilter(filters){
        const plane = [];
        for (const filter of filters) {
          for (const cond of filter.conditions) {
            plane.push({
              fields: filter.fields,
              optional: filter.optional,
              operation: cond.operation,
              value: cond.value
            });
          }
        }
        const grouped = _.groupBy(plane, (item) => {
          return [item.fields, item.operation, item.optional];
        });
        return grouped
      },
      ucFirst(str) {
        if (!str) return str;
        return str[0].toUpperCase() + str.slice(1);
      },
      searchOptionsFilter(filters){
        let result = {
          match: [],
          notMatch: []
        }
        filters.forEach(filter=>{
          if (filter.conditions[0].operation === 'match_query'){
            result.match.push(filter.conditions[0].value)
          } else {
            result.notMatch.push(filter.conditions[0].value)
          }
        })
        return result
      },

    },
}
</script>
<style>

body .page-header.card {
  margin-left: 0 !important;
  margin-bottom: 20px;
  /*margin-top: 8px;*/
  margin-top: 0!important;
}

body .page-header-title.without-info h5 {
    min-height: 40px;
}

.header-heading {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

</style>
<style lang="scss" scoped>

.optionsWidthHeader{
  max-width: 100%;
  text-align: left!important;
  max-height: 68px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropstart{
  .primaryIcon{
    cursor: pointer;
    color: #4099ff !important;
    width: auto;
    height: auto;
    border: none;
    box-shadow: none;
  }
}
@media only screen and (max-width: 768px) {
  .card{
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .options {
    font-size: 0.8em !important;
  }
  .coin {
    padding-top: 0 !important;
  }
}
.coin{
  padding-top: 2px;
}
//.page-header-title{
//  .dropdown-toggle::after{
//    display: none !important;
//  }
//}

</style>